import React, { useContext } from 'react';
import Button from '../common/button/Button';
import Translate from '../locale/Translate';
import { useUserActions } from '../user/UserActionProvider';
import { UserContext } from '../user/UserProvider';
import { Outlet } from 'react-router';
import { useAuth } from 'react-oidc-context';

const PrivateRoute = (): JSX.Element => {
  const { login } = useContext(UserContext);
  const auth = useAuth();
  const { loginOpen } = useUserActions();

  return auth.isAuthenticated ? (
    <Outlet />
  ) : (
    <div className="container py-3 text-1">
      {!loginOpen && (
        <>
          <h1 className="heading-1 text-center">
            <Translate id="private.header" />
          </h1>
          <p className=" text-center p-3">
            <Translate id="private.body" />
          </p>
          <div className="flex justify-center py-3">
            <Button className="m-1" onClick={login}>
              <Translate id="private.login-btn" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PrivateRoute;

export const PrivateRouteTexts = {
  no: {
    'private.header': 'Hei!',
    'private.body': 'Du må være logget inn for å se dette innholdet.',
    'private.login-btn': 'Logg inn',
    'private.back-btn': 'Tilbake til startsiden',
  },
  en: {
    'private.header': 'Hi!',
    'private.body': 'You have to log in to see this content.',
    'private.login-btn': 'Logg inn',
    'private.back-btn': 'Go back to the start page',
  },
};
