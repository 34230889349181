/* eslint-disable  */
import React, { useState, useEffect, useContext } from 'react';
import Translate from '../locale/Translate';
import useInterval from '../../utlis/UseInterval';
import { useApi } from '../../api/apiProvider';
import { useUser } from './UserProvider';
import { SERVICE_ID } from '../../api/serviceInfo';
import { useUserActions } from './UserActionProvider';
import { UserContext } from '../user/UserProvider';

interface RegisteredUserSuccessPageProps {
  user: { username: string };
}

const RegisteredUserSuccessPage = (props: RegisteredUserSuccessPageProps): JSX.Element => {
  const { user } = props;

  const [loading, setLoading] = useState(false);

  // const { state } = useLocation<LocationState>();
  const { urls, api } = useApi();
  const { toggleCreateUser } = useUserActions();
  const { login } = useContext(UserContext);

  return (
    <>
      <h1 className="heading-1 m-2">
        <Translate id="confirmEmailPage.heading" fallback="Nesten ferdig..." />
      </h1>

      <p className="m-2">
        <Translate id="confirmEmailPage.info.completeregistration" fallback="" />
        {user?.username ? (
          <>
            : <b>{user.username}</b>.
          </>
        ) : (
          '.'
        )}
      </p>
      <p className="m-2">
        <Translate id="confirmEmailPage.info.notreceivingemail" fallback="" />
        <b className="font-bold">brukerportal-noreply@bymoslo.no</b>.
      </p>
      <p className="m-2">
        <Translate id="confirmEmailPage.info.loginafteractivation" fallback="" />
      </p>
      <p className="m-2">
        <Translate
          id="confirmEmailPage.info.experienceproblems"
          fallback=""
          values={{
            chatLink: (chunks: any[]) => (
              <a
                className=" font-bold text-blue-dark "
                target="_blank"
                href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten"
                rel="noopener noreferrer"
                tabIndex={0}
              >
                {chunks}
              </a>
            ),
          }}
        />
      </p>
      <p className="m-2">
        <Translate id="confirmEmailPage.info.closethismessage" fallback="" />
      </p>
    </>
  );
};

export default RegisteredUserSuccessPage;
