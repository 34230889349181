import React, { FunctionComponent, ReactNode, ReactNodeArray, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useIntl } from 'react-intl';
import Button from '../button/Button';
import Icon from '../icons/Icon';
import './Modal.scss';

interface ModalProps {
  toggle?: () => any;
  isOpen?: boolean;
  children: ReactNodeArray | ReactNode;
  closeButton?: boolean;
  preventScroll?: boolean;
}
export default function BymeldingModal({
  children,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggle = () => {},
  isOpen = false,
  closeButton = true,
  preventScroll = false,
}: ModalProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [yPosition, setYPosition] = useState(0);

  useEffect(() => {
    setYPosition(window.scrollY);
    if (preventScroll) {
      if (isOpen)
        document.body.setAttribute(
          'style',
          `position: fixed: top: -${yPosition}px; left:0; rigth: 0; overflow: hidden;`
        );
      else document.body.setAttribute('style', '');
    }
  }, [isOpen, preventScroll, yPosition]);

  // TODO: This is probably bad practice and should be refactored
  const scrollHack = (): void => {
    const container = document.querySelector('.ReactModal__Content');
    if (container) {
      container.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    return () => {
      if (preventScroll) document.body.setAttribute('style', '');
    };
  }, [preventScroll]);

  Modal.setAppElement('#root');

  return (
    <Modal
      isOpen={isOpen}
      className="w-full max-h-full overflow-y-auto tablet:w-10/12 widescreen:w-6/12 pt-7 tablet:pt-6"
      overlayClassName="modal-backdrop z-modalBackdrop bg-black-backdrop"
      onAfterOpen={scrollHack}
      onRequestClose={toggle}
    >
      <div className="w-100 relative pb-7 tablet:pb-6 flex flex-col bg-white">
        <div className="close-button-section border-0 mobile-only:bg-white">
          <div className="bg-white w-full" />
          <div className="mobile-only:relative mobile-only:m-3">
            <Button
              aria-label={formatMessage({ id: 'modal.close-btn' })}
              colorOption="yellow"
              modifier="circle"
              disabled={!closeButton}
              onClick={toggle}
            >
              <Icon icon="x" className="heading-2" />
            </Button>
          </div>
        </div>
        <div className="px-3 tablet:px-5 ">{children}</div>
      </div>
    </Modal>
  );
}

interface ModalHeaderProps {
  children: {
    heading?: ReactNode;
    subheading?: ReactNode;
    restcontent?: ReactNode;
  };
}
export const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
  children: { heading, subheading, restcontent },
}) => {
  return (
    <div className="modal-header bg-white">
      {typeof heading === 'string' ? <h1 className="heading-modal mb-2">{heading}</h1> : heading}
      {typeof subheading === 'string' ? <p className="heading-modal-sub">{subheading}</p> : subheading}
      {restcontent}
    </div>
  );
};

interface ModalSectionProps {
  className?: string;
  children: React.ReactNode;
}
export const ModalSection: FunctionComponent<ModalSectionProps> = ({ children, className }) => {
  return (
    <div className={`mb-4 mobile-only:mb-2 mobile-only:mt-1 last:mb-2 first:mt-2   ${className ?? ''}`}>{children}</div>
  );
};

export const ModalTranslations = {
  no: {
    'modal.close-btn': 'Lukk',
  },
  en: {
    'modal.close-btn': 'Close',
  },
};
