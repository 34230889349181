import React, { useRef } from 'react';
import BrowserChecker from '../browserChecker/BrowserChecker';
import Icon from '../common/icons/Icon';
import { useIntl } from 'react-intl';
import Translate from '../locale/Translate';

const FaqPage = (): JSX.Element => {
  const scrollToForgotPassword = useRef<HTMLParagraphElement | null>(null);
  const scrollTo300CharsRef = useRef<HTMLParagraphElement | null>(null);
  const scrollToCatRef = useRef<HTMLParagraphElement | null>(null);
  const scrollToWhatHappensRef = useRef<HTMLParagraphElement | null>(null);
  const scrollToAppRemovalReasonRef = useRef<HTMLParagraphElement | null>(null);
  const scrollToMapLocationManualReasonRef = useRef<HTMLParagraphElement | null>(null);
  const { formatMessage } = useIntl();
  const contactEmail = (chunks: any[]): JSX.Element => {
    return <a href="mailto:postmottak@bym.oslo.kommune.no">{chunks}</a>;
  };
  return (
    <>
      <BrowserChecker />
      <div className="container mx-auto p-5 flex flex-col pb-5 text-3 justify-center items-stretch">
        <h1 className="heading-1 pb-5">
          <Translate id="faq.heading" />
        </h1>
        <a
          onClick={() => scrollToForgotPassword?.current?.scrollIntoView({ behavior: 'smooth' })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              scrollTo300CharsRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          className="text-3 p-3 text-left flex align-middle hover:cursor-pointer w-fit"
          tabIndex={0}
        >
          <Icon
            aria-label={`Lenk til ${formatMessage({ id: 'summary.descriptionLengthFQAPopupHeader' })}`}
            role="link"
            icon="chevron-right"
            className="mr-3"
          />
          <span className="hover:underline">
            <Translate id="faq.forgot.password.header" fallback="Jeg husker ikke passordet mitt. Hva gjør jeg nå?" />
          </span>
        </a>
        <a
          onClick={() => scrollTo300CharsRef?.current?.scrollIntoView({ behavior: 'smooth' })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              scrollTo300CharsRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          className="text-3 p-3 text-left flex align-middle hover:cursor-pointer w-fit"
          tabIndex={0}
        >
          <Icon
            aria-label={`Lenk til ${formatMessage({ id: 'summary.descriptionLengthFQAPopupHeader' })}`}
            role="link"
            icon="chevron-right"
            className="mr-3"
          />
          <span className="hover:underline">
            <Translate
              id="summary.descriptionLengthFQAPopupHeader"
              fallback="Begrensning på 300 tegn"
              values={{
                contactEmail: contactEmail,
              }}
            />
          </span>
        </a>
        <a
          onClick={() => scrollToCatRef?.current?.scrollIntoView({ behavior: 'smooth' })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              scrollToCatRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          className="text-3 p-3 text-left flex align-middle hover:cursor-pointer w-fit"
          tabIndex={0}
        >
          <Icon
            aria-label={`Lenk til ${formatMessage({ id: 'kategoriSok.empty_result_popup_header_faq_text' })}`}
            role="link"
            icon="chevron-right"
            className="mr-3"
          />
          <span className="hover:underline">
            <Translate
              id="kategoriSok.empty_result_popup_header_faq_text"
              fallback="Hvorfor finnes det ikke en «Annet»-kategori?"
              values={{
                contactEmail: contactEmail,
              }}
            />
          </span>
        </a>
        <a
          onClick={() => scrollToWhatHappensRef?.current?.scrollIntoView({ behavior: 'smooth' })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              scrollToWhatHappensRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          className="text-3 p-3 text-left flex align-middle hover:cursor-pointer w-fit"
          tabIndex={0}
        >
          <Icon
            aria-label={`Lenk til ${formatMessage({ id: 'innmelding-bekreft.faq.header' })}`}
            role="link"
            icon="chevron-right"
            className="mr-3"
          />
          <span className="hover:underline ">
            <Translate id="innmelding-bekreft.faq.header" fallback="Hva skjer med en melding når den sendes inn?" />
          </span>
        </a>
        <a
          onClick={() => scrollToAppRemovalReasonRef?.current?.scrollIntoView({ behavior: 'smooth' })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              scrollToAppRemovalReasonRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          className="text-3 p-3 text-left flex align-middle hover:cursor-pointer w-fit"
          tabIndex={0}
        >
          <Icon
            aria-label={`Lenk til ${formatMessage({ id: 'faq.app.removal.reason.header' })}`}
            role="link"
            icon="chevron-right"
            className="mr-3"
          />
          <span className="hover:underline ">
            <Translate id="faq.app.removal.reason.header" fallback="Hvorfor finnes det ikke en app?" />
          </span>
        </a>
        <a
          onClick={() => scrollToAppRemovalReasonRef?.current?.scrollIntoView({ behavior: 'smooth' })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              scrollToAppRemovalReasonRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          className="text-3 p-3 text-left flex align-middle hover:cursor-pointer w-fit"
          tabIndex={0}
        >
          <Icon
            aria-label={`Lenk til ${formatMessage({ id: 'faq.map.location.manual.reason.header' })}`}
            role="link"
            icon="chevron-right"
            className="mr-3"
          />
          <span className="hover:underline">
            <Translate
              id="faq.map.location.manual.reason.header"
              fallback="Hvorfor må jeg sette markør manuelt i kartet?"
            />
          </span>
        </a>
        <div ref={scrollToForgotPassword}>
          <h2 className="heading-3 my-3">
            <Translate id="faq.forgot.password.header" />
          </h2>
          <div className="w-full my-3 text-3 tablet:text-2">
            <Translate id="faq.forgot.password.text" />
          </div>
        </div>
        <div ref={scrollTo300CharsRef}>
          <h2 className="heading-3 my-3">
            <Translate id="summary.descriptionLengthFQAPopupHeader" />
          </h2>
          <div className="w-full my-3 text-3 tablet:text-2">
            <Translate
              id="summary.descriptionLengthWarning"
              values={{
                contactEmail: contactEmail,
              }}
            />
          </div>
        </div>
        <div ref={scrollToCatRef}>
          <h2 className="heading-3 my-3">
            <Translate id="kategoriSok.empty_result_popup_header_faq_text" />
          </h2>
          <div className="w-full my-3 text-3 tablet:text-2">
            <Translate
              id="kategoriSok.empty_result_popup_body_faq_text"
              values={{
                contactEmail: contactEmail,
              }}
            />
          </div>
        </div>
        <div ref={scrollToWhatHappensRef}>
          <h2 className="heading-3 my-3">
            <Translate id="innmelding-bekreft.faq.header" />
          </h2>
          <div className="w-full my-3 text-3 tablet:text-2">
            <Translate id="innmelding-bekreft.faq.section1" />
          </div>
          <div className="w-full my-3 text-3 tablet:text-2">
            <Translate id="innmelding-bekreft.faq.section2" />
          </div>
          <div className="w-full my-3 text-3 tablet:text-2">
            <Translate id="innmelding-bekreft.faq.section3" />
          </div>
        </div>
        <div ref={scrollToAppRemovalReasonRef}>
          <h2 className="heading-3 my-3">
            <Translate id="faq.app.removal.reason.header" />
          </h2>
          <div className="w-full my-3 text-3 tablet:text-2">
            <Translate id="faq.app.removal.reason.text" />
          </div>
        </div>
        <div ref={scrollToMapLocationManualReasonRef}>
          <h2 className="heading-3 my-3">
            <Translate id="faq.map.location.manual.reason.header" />
          </h2>
          <div className="w-full my-3 text-3 tablet:text-2">
            <Translate id="faq.map.location.manual.reason.text" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqPage;
