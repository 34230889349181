/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import * as urls from './api';
import { CLIENT_NAME } from '../api/serviceInfo';
import { oidcConfig } from '../oidcConfig';
import { User } from 'oidc-client-ts';

interface UseApiInterface {
  urls?: any;
  setToken?: any;
  removeTokenfromHeader?: any;
  api?: any;
  version?: string;
}

const getUpdatedToken = (): string | null => {
  const oidcStorage = localStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);

  if (oidcStorage) {
    const user = User.fromStorageString(oidcStorage);
    return user?.access_token ?? null;
  }

  return null;
};

const ApiProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;
  const apiRef = useRef(axios.create({}));
  apiRef.current.interceptors.request.use((config) => {
    const token = getUpdatedToken();
    if (token) {
      config.headers = Object.assign({}, config.headers, {
        Authorization: `Bearer ${token}`,
        'X-Bymelding-Client': `${CLIENT_NAME}`,
      });
    }

    return config;
  });

  const [v, setV] = useState('');

  const getAppVersion = async (): Promise<void> => {
    let version = '?';

    try {
      const versionResponse = await axios.get(`/version.txt`);
      version = versionResponse?.data || version;
      setV(version);
    } catch {
      version = 'Ingen versjon funnet';
    } finally {
      apiRef.current.defaults.headers['X-Bymelding-Client'] = `${CLIENT_NAME}:${version || 'xx'}`;
    }
  };

  const init = useCallback(async (): Promise<void> => {
    await getAppVersion();
  }, []);

  useEffect((): void => {
    init();
  }, [init]);

  return <ApiContext.Provider value={{ urls, api: apiRef.current, version: v }}>{children}</ApiContext.Provider>;
};

export const ApiContext = createContext({});
export const useApi = (): UseApiInterface => useContext(ApiContext);
export default ApiProvider;
