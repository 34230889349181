const getHostFromEnv = (service: string): string => {
  const key = `REACT_APP_${service}`;
  const host = (window as any)[key] || process.env[key];
  if (host != null) return host;
  throw new Error(`Missing environment configuration with key '${key}'`);
};

const service = `${getHostFromEnv('URL_BYMELDING_SERVICE')}/api`;
const notificationService = `${getHostFromEnv('URL_BYMELDING_NOTIFICATION_SERVICE')}/api`;

const samtykkeservice = `${getHostFromEnv('URL_SAMTYKKE_SERVICE')}/api`;

const autentiseringService = `${getHostFromEnv('URL_AUTENTISERING_SERVICE')}/api`; // denne skal helt bort!

const bymeldingSokService = `${getHostFromEnv('URL_BYMELDING_SOK_SERVICE')}/sok`;

const bymeldingObjektService = `${getHostFromEnv('URL_BYMELDING_OBJEKT_SERVICE')}/api`;

const bymeldingRutingService = `${getHostFromEnv('URL_BYMELDING_RUTING_SERVICE')}/api`;

// Bymelding
export const getMessageCategories = (): string => `${service}/meldingskategorigrupper`;
export const postMessage = (): string => `${service}/meldinger`;
export const postAnonymMessage = (): string => `${service}/meldinger/anonym`;
export const statusoppdateringer = (ikkeBehandlet: boolean, nyligBehandlet: boolean): string =>
  `${bymeldingSokService}/melding/publikum?ikkeBehandlet=${ikkeBehandlet}&nyligBehandlet=${nyligBehandlet}`;
export const fulgtmelding = (meldingId: string): string =>
  `${bymeldingSokService}/melding/publikum/fulgte?meldingIds=${meldingId}`;

export const imageUrl = (imageId: string): string => `${service}/bilder/${imageId}`;
export const getAdresse = (lat: string, lng: string, begrensTilOslo?: boolean): string =>
  `${service}/adresseoppslag?latitude=${lat}&longitude=${lng}${
    begrensTilOslo === false ? '&begrenstiloslo=false' : ''
  }`;
// export const getPublikumMeldinger = (): string => `${service}/publikum/meldinger/`;
export const getBydel = (lat: string, lng: string): string => `${service}/kart/feature/arcgis/${lng}/${lat}?layerId=2`;
export const meldingabonnement = (): string => `${notificationService}/meldingabonnement`;
export const getPublikumMelding = (brukerId: string, meldingId: string): string =>
  `${service}/publikum/brukere/${brukerId}/meldinger/${meldingId}`;

// - Brukerhåndtering
//export const login = (): string => `${autentiseringService}/token`;
//export const resetPassword = (): string => `${autentiseringService}/glemtpassord`;
export const resetPassword = (): string => `${service}/glemtpassord`;
export const changePassword = (brukerId: string): string => `${service}/publikum/brukere/${brukerId}/passord`;
export const setnewPassword = (): string => `${autentiseringService}/glemtpassord`;

export const registerUser = (): string => `${service}/publikum/brukere`;
export const brukerAktivering = (key: string): string => `${service}/publikum/brukere/${key}/aktiver`;
export const aktiverBrukerMedPassord = (key: string): string =>
  `${autentiseringService}/brukeraktiveringer/${key}/passord`;

// - Vilkår
export const getSamtykke = (klientId: string, brukerId: string): string =>
  `${samtykkeservice}/klienter/${klientId}/samtykker/bruker/${brukerId}`;
export const slettSamtykke = (klientId: string, samtykkeId: string): string =>
  `${samtykkeservice}/klienter/${klientId}/samtykker/${samtykkeId}`;
export const postSamtykke = (klientId: string): string => `${samtykkeservice}/klienter/${klientId}/samtykker`;
export const getVilkaar = (klientId: string, locale: string): string =>
  `${samtykkeservice}/klienter/${klientId}/vilkaar?spraak=${locale}`;

// Notificationsservice
export const getNotificationServiceVarselData = (klientKey: string): string =>
  `${notificationService}/varsel?klientKey=${klientKey}`;

// objektService
export const getObjektServiceStatiskeData = (): string => `${bymeldingObjektService}/statiskedata`;
export const getObjektServiceObjekter = (
  southWestLng: string,
  southWestLat: string,
  northEastLng: string,
  northEastLat: string,
  meldingstypeId?: string,
  brukerId?: string
): string =>
  `${bymeldingObjektService}/objekter?boundingBox=${southWestLng},${southWestLat},${northEastLng},${northEastLat}&meldingstypeId=${
    meldingstypeId || ''
  }&brukerId=${brukerId ?? ''}`;

export const findObjektServiceObjekter = (meldingstypeId: string, searchString: string): string =>
  `${bymeldingObjektService}/objekter/find?meldingstypeId=${meldingstypeId || ''}&searchString=${searchString}`;

// ClientInfo
export const clientInfo = (): string => `${service}/clientinfo`;
export const clientInfoByClientId = (key: string): string => `${service}/clientinfo/${key}`;

// Søk
export const meldingskategoriSok = (soketekst: string): string =>
  `${bymeldingSokService}/meldingstype/autocomplete?tekst=${soketekst}`;
export const aktiveMeldingerIOmraadeSok = (
  southWestLng: string,
  southWestLat: string,
  northEastLng: string,
  northEastLat: string,
  kategori?: string
): string =>
  `${bymeldingSokService}/melding/publikum/aktiveiomraade?boundingBox=${southWestLng},${southWestLat},${northEastLng},${northEastLat}&meldingstypeId=${
    kategori || ''
  }`;

export const fulgtemeldinger = (ikkeBehandlet: boolean, nyligBehandlet: boolean): string =>
  `${notificationService}/meldingabonnement/meldinger?ikkeBehandlet=${ikkeBehandlet}&nyligBehandlet=${nyligBehandlet}`;

// Ruting
export const simulerRuting = (lat: number, lng: number, meldingstypeId: string): string =>
  `${bymeldingRutingService}/melding/simuler/${lat}/${lng}/${meldingstypeId}`;
