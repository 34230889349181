/* eslint-disable global-require */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import Spinner from './features/common/spinner/Spinner';
import AppErrorBoundary from './features/common/AppErrorBoundary';

// import LoginProvider from "./features/user/LoginProvider";
import UserProvider from './features/user/UserProvider';
import UserActionProvider from './features/user/UserActionProvider';
import ScrollToTop from './features/common/ScrollToTop';
import { FeatureFlagHandler } from './utlis/FeatureFlagUtils';

import LocaleProvider, { nbConfig } from './features/locale/LocaleProvider';
import ApiProvider from './api/apiProvider';
import SamtykkeContext from './features/samtykke/SamtykkeProvider';
import App from './App';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';

import './index.scss';
import './assets/main.css';

import { oidcConfig } from './oidcConfig';

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <LocaleProvider defaultConfig={nbConfig}>
    <ServiceWorkerWrapper>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider {...oidcConfig}>
            <ApiProvider>
              <FeatureFlagHandler>
                <UserProvider>
                  <SamtykkeContext>
                    <ScrollToTop />
                    <UserActionProvider>
                      <AppErrorBoundary>
                        <Suspense fallback={<Spinner />}>
                          <App />
                        </Suspense>
                      </AppErrorBoundary>
                    </UserActionProvider>
                  </SamtykkeContext>
                </UserProvider>
              </FeatureFlagHandler>
            </ApiProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ServiceWorkerWrapper>
  </LocaleProvider>
);

// NB: Serviceworker is registered the ServiceWorkerWrapper component

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
