import React, { createContext, useEffect, useState, useContext} from 'react';
import { Bruker } from '../../interfaces/userInterface';
import { useAuth } from 'react-oidc-context';


const initBruker: Bruker = {
  brukerId: '',
  epost: '',
  roller: [],
};

interface UserContext {
  user: Bruker;
  login: () => void;
  logout: () => void;
}

export const UserContext = createContext({} as UserContext);

const UserProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;
  const [user, setUser] = useState(initBruker);
  const auth = useAuth();

  const getBrukerId = (auth: any): string | undefined => {
    const bymId = auth?.user?.profile?.params?.['bym-id'];
    return bymId ? String(bymId) : undefined;
  };

  const login = (): void => {
    auth.signinRedirect();
  };

  const logout = (): void => {
    auth.signoutRedirect();
  };

  useEffect((): void => {
    if (!auth.isAuthenticated) return;

    setUser({
      brukerId: getBrukerId(auth) ?? '',
      epost: auth.user?.profile.email ?? '',
      roller: [], // todo
    });
  }, [auth, auth.isAuthenticated]);

  return <UserContext.Provider value={{ user, login, logout }}>{children}</UserContext.Provider>;
};

export default UserProvider;

export const useUser = (): UserContext => useContext(UserContext);
