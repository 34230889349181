import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ReactComponent as BellIcon } from '../../images/bell.svg';
import { ReactComponent as OkBlackIcon } from '../../images/oslo_logo_header.svg';
import LanguagePicker from '../locale/LanguagePicker';
import Translate from '../locale/Translate';
import { useUserActions } from '../user/UserActionProvider';
import PulloutMenu from './PulloutMenu';
import NavbarButton from './NavbarButton';
import NavbarLinkButton from './NavbarLinkButton';
import { routes } from '../routes/BymeldingGlobalRoutes';
import { UserContext } from '../user/UserProvider';
import { getAndroidTWAStatus } from '../../utlis/urlUtils';

import './BymeldingPublikumNavbar.scss';
import FocusTrap from '../common/FocusTrap/FocusTrap';
import { useAuth } from 'react-oidc-context';

const BymeldingPublikumNavbar = (): JSX.Element => {
  const { login } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = (): void => setIsOpen(!isOpen);
  const [yPosition, setYPosition] = useState(0);
  const { toggleCreateUser } = useUserActions();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const KEY_NAME_ESC = 'Escape';
  const KEY_EVENT_TYPE = 'keyup';
  const { logout } = useContext(UserContext);

  const location = useLocation();

  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KEY_NAME_ESC) {
        if (isOpen) setIsOpen(!isOpen);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    setIsOpen(false);
  }, [location, setIsOpen]);

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey);
    };
  }, [handleEscKey]);
  useEffect(() => {
    setYPosition(window.scrollY);
    if (isOpen)
      document.body.setAttribute('style', `position: fixed: top: -${yPosition}px; left:0; rigth: 0; overflow: hidden;`);
    else document.body.setAttribute('style', '');
  }, [isOpen]);

  useEffect(() => {
    return document.body.setAttribute('style', '');
  }, []);

  const liLinkClass = `
    hover:underline
    focus:underline
    text-blue-dark
    visited:text-blue-dark
    text-1
  `;
  const liClass = `
  ml-1
  pb-5
  mobile-only:pb-3
`;
  const auth = useAuth();

  return (
    <>
      <nav className="bymelding-publikum-navbar bg-white h-nav-large mobile-only:h-nav-small flex items-center justify-between flex-no-wrap z-nav px-3">
        <Link className="ml-3 " to={routes.home} aria-label="hjem">
          <OkBlackIcon className="ok-icon mobile-only:h-logo-small h-logo-large" />
        </Link>
        {!getAndroidTWAStatus() && (
        <div className="flex items-center flex-row flex-no-wrap align-middle">
          {auth.isAuthenticated ? (
            <>
                <NavbarLinkButton text={formatMessage({ id: 'navbar.messages' })} to={'/meldinger'}>
                <BellIcon width="32px" height="32px" aria-label={formatMessage({ id: 'navbar.messages' })} />
              </NavbarLinkButton>
              <NavbarLinkButton
                className="mobile-only:hidden"
                text={formatMessage({ id: 'navbar.profile', defaultMessage: 'Min profil' })}
                icon="user"
                to={routes.myProfile}
              />
            </>
          ) : (
            <NavbarButton
              className={`${liLinkClass} `}
              text={formatMessage({ id: 'navbar.login', defaultMessage: 'Logg inn' })}
              icon="user"
              onClick={login}
            />
          )}
          <button
            type="button"
            onClick={toggleOpen}
            name={formatMessage({ id: 'navbar.meny-toggle' })}
            aria-haspopup="true"
            aria-label={formatMessage({ id: 'navbar.meny-toggle' })}
            className="cursor-pointer border-0 bg-transparent text-blue-dark mobile-only:h-logo-small h-logo-large ml-3"
          >
            <i className="osg-icon-menu bymelding-publikum-navbar-menu-button-icon" />
          </button>
        </div>
        )}
      </nav>
      <PulloutMenu isOpen={isOpen} toggleOpen={toggleOpen}>
        <FocusTrap>
          <div>
            <ul className="bymelding-pullout-menu-content mt-7">
              <li className={liClass}>
                <Link to={'/innmelding'} className={liLinkClass} tabIndex={isOpen ? 0 : -1}>
                  <Translate id="navbar.home" fallback="Bymelding" />
                </Link>
              </li>
              {auth.isAuthenticated ? (
                <>
                  <li className={liClass}>
                    <Link className={liLinkClass} role="menuitem" to="/meldinger" tabIndex={isOpen ? 0 : -1}>
                      <Translate id="navbar.messages" fallback="Statusoppdateringer" />
                    </Link>
                  </li>
                  <li className={liClass}>
                    <Link className={liLinkClass} role="menuitem" to={routes.myProfile} tabIndex={isOpen ? 0 : -1}>
                      <Translate id="navbar.profile" fallback="Min profil" />
                    </Link>
                  </li>
                  <li className={liClass}>
                    <button
                      role="menuitem"
                      tabIndex={isOpen ? 0 : -1}
                      className={`navbar-btn ${liLinkClass} bg-white border-0 text-1 cursor-pointer hover:underline`}
                      type="button"
                      onClick={() => {
                        setIsOpen(false);
                        logout();
                        navigate('/');
                      }}
                    >
                      <Translate id="navbar.logout" fallback="Logg ut" />
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li className={liClass}>
                    <button
                      role="menuitem"
                      tabIndex={isOpen ? 0 : -1}
                      className={`navbar-btn ${liLinkClass} bg-white border-0 text-1 cursor-pointer hover:underline`}
                      type="button"
                      onClick={() => {
                        login();
                        setIsOpen(false);
                      }}
                    >
                      <Translate id="navbar.login" fallback="logg inn" />
                    </button>
                  </li>
                  <li className={liClass}>
                    <button
                      role="menuitem"
                      tabIndex={isOpen ? 0 : -1}
                      className={`navbar-btn ${liLinkClass} bg-white border-0 text-1 cursor-pointer hover:underline`}
                      type="button"
                      onClick={() => {
                        toggleCreateUser();
                        setIsOpen(false);
                      }}
                    >
                      <Translate id="navbar.register" fallback="Opprett bruker" />
                    </button>
                  </li>
                </>
              )}
              <li className={liClass} />
              <li className={liClass}>
                <Link role="menuitem" to={routes.eula} className={liLinkClass} tabIndex={isOpen ? 0 : -1}>
                  <Translate id="navbar.eula" fallback="Vilkår og personvern" />
                </Link>
              </li>
              <li className={liClass}>
                <Link role="menuitem" to={routes.faq} className={liLinkClass} tabIndex={isOpen ? 0 : -1}>
                  <Translate id="navbar.faq" fallback="Ofte stilte spørsmål" />
                </Link>
              </li>
              <li className={liClass}>
                <Link role="menuitem" to={routes.contact} className={liLinkClass} tabIndex={isOpen ? 0 : -1}>
                  <Translate id="navbar.contact" fallback="Kontakt oss" />
                </Link>
              </li>
            </ul>

            <div role="menuitem" className="osg-menu-links mb-6 flex-end">
              <LanguagePicker tabIndex={isOpen ? 0 : -1} />
            </div>
          </div>
        </FocusTrap>
      </PulloutMenu>
    </>
  );
};

export default BymeldingPublikumNavbar;
