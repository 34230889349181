/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Suspense } from 'react';
import Spinner from './features/common/spinner/Spinner';
import BymeldingPublikumNavbar from './features/navbar/BymeldingPublikumNavbar';
import BymeldingGlobalRoutes from './features/routes/BymeldingGlobalRoutes';
import { initilizeFaLibrary } from './features/common/icons/FaIcon';
import CookiePopup from './features/cookiePopup/CookiePopup';
import Translate from './features/locale/Translate';
import './App.scss';

const App = (): JSX.Element => {
  useEffect(() => {
    initilizeFaLibrary();
  }, []);

  return (
    <div className="flex flex-col items-stretch min-h-screen">
      <a href="#content" aria-label="Hopp til hovedinnholdet" className="snarvei" tabIndex={0}>
        <span className="heading-2">
          <Translate id="navbar.navbarMainShorcut" />
        </span>
      </a>
      <BymeldingPublikumNavbar />
      <div id="content" className="">
        <Suspense fallback={<Spinner />}>
          <BymeldingGlobalRoutes />
        </Suspense>
      </div>
      <CookiePopup />
    </div>
  );
};

export default App;
