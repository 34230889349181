/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import TextButton from '../common/button/TextButton';
import Inputfield from '../common/forms/Inputfield';
import Translate from '../locale/Translate';
import VilkaarPopupOnlyText from '../samtykke/VilkaarPopupOnlyText';
import Checkbox from '../common/forms/Checkbox';
import Button from '../common/button/Button';
import { useApi } from '../../api/apiProvider';
import { SERVICE_ID } from '../../api/serviceInfo';
import { useSamtykke } from '../samtykke/SamtykkeProvider';
import Spinner from '../common/spinner/Spinner';
import { useUserActions } from './UserActionProvider';
import { useYupValidationResolver } from './useYupValidationResolver';

const RegistrerForm = ({
  onRegisterSuccess,
}: {
  onRegisterSuccess: { (user: { username: string }): void };
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const { formatMessage } = useIntl();
  const { urls, api } = useApi();
  const { acceptSamtykke, vilkaar } = useSamtykke();
  const { toggleGlemtPassord } = useUserActions();

  const registerUserSchema = useMemo(
    () =>
      yup.object({
        username: yup.string().lowercase().required('validatation.required').email('validatation.notavalidemail'),
        acceptTermsAndConditions: yup.bool().oneOf([true], 'validatation.termsandcondition'),
        hcUser: yup.bool(),
      }),
    []
  );

  const resolver = useYupValidationResolver(registerUserSchema);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<{
    username: string;
    acceptTermsAndConditions: boolean;
    hcUser: boolean;
  }>({
    mode: 'onSubmit',
    resolver,
  });

  const registerUser = handleSubmit(async ({ username, acceptTermsAndConditions }) => {
    setLoading(true);
    setUserExists(false);
    try {
      const url = urls.registerUser();
      const res = await api.post(url, {
        navn: username,
        epost: username,
        serviceId: SERVICE_ID,
      });
      const userId = res.data.result.id;
      if (userId && vilkaar.id && acceptTermsAndConditions) await acceptSamtykke(vilkaar.id, userId);
      if (onRegisterSuccess) onRegisterSuccess({ username });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e?.response?.data.errorMessage.includes('ErrorId: 851a41f7-1b8a-41fa-b9f4-716cde59e77a')) {
        setUserExists(true);
        setError('username', { type: 'conflict', message: 'validatation.emailconflict.short' });
      }
    }
  });

  return (
    <>
      <form name="create_user_form" onSubmit={registerUser}>
        <Inputfield
          autoFocus
          autoComplete="off"
          type="email"
          label={formatMessage({
            id: 'user.form-email',
            defaultMessage: 'Din E-post',
          })}
          error={
            errors.username?.message &&
            formatMessage({
              id: errors.username.message.toString(),
              defaultMessage: ' ',
            })
          }
          {...register('username')}
          required
        />

        <div className="flex flex-row flex-wrap mb-3 align-middle text-5">
          <Translate
            id="registration.form-termsandconditions"
            values={{
              checkbox: (chunks: ReactNode) => (
                <Checkbox
                  className="inline p-0 mx-2 underline bg-white border-0 cursor-pointer hover:text-blue focus:text-blue color-blue-dark"
                  rootClassName="mr-1 mb-0"
                  {...register('acceptTermsAndConditions')}
                  error={
                    errors.acceptTermsAndConditions
                      ? formatMessage({ id: errors.acceptTermsAndConditions?.message?.toString() })
                      : ''
                  }
                  required
                >
                  {chunks}
                </Checkbox>
              ),
              toggleInfo: (chunks: ReactNode) => (
                <TextButton
                  className="ml-1"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowTermsAndConditions(true);
                  }}
                >
                  {chunks}
                </TextButton>
              ),
            }}
          />
        </div>
        {errors.acceptTermsAndConditions && (
          <div className="p-3 mt-2 mb-2 bg-state-danger">
            <Translate id={errors.acceptTermsAndConditions.message?.toString() ?? ''} />
          </div>
        )}

        <Button
          type="submit"
          form="create_user_form"
          className="w-full text-5"
          disabled={loading}
          onClick={registerUser}
        >
          <Translate id="registration.form-submit" />
        </Button>
      </form>
      {userExists && (
        <div className="p-3 mt-2 whitespace-pre-line bg-state-warning">
          <Translate
            id="registration.form-forgotPasword"
            values={{
              glemtPasordLink: (chunks: ReactNode) => (
                <TextButton
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleGlemtPassord();
                  }}
                >
                  {chunks}
                </TextButton>
              ),
            }}
          />
        </div>
      )}
      <VilkaarPopupOnlyText
        isOpen={showTermsAndConditions}
        toggleOpen={() => {
          setShowTermsAndConditions(!showTermsAndConditions);
        }}
      />
      {loading && <Spinner />}
    </>
  );
};

export default RegistrerForm;
